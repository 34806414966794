import React, { Component } from "react";
import styles from "./Header.module.css";
import { gsap } from "gsap";
import avatar from "../assets/images/avatar.jpg";

class Header extends Component {
  // state = {  }
  render() {
    return (
      <header>
        <h3>
          Hello! <br />
          My name is Alireza Shafi'i.
        </h3>
        <span className={styles.mono}>I go by Ali, he/him pronouns.</span>
        <img className={styles.avatar} src={avatar} alt="Avatar" />
        <p className={styles.paragraphOne}>
          I'm a design-minded <span>front-end developer</span> focused on
          building beautiful interfaces and experiences.
        </p>
        <p className={styles.paragraphTwo}>
          As a <span>computer engineer</span>, I enjoy bridging the gap between engineering
          and design — combining my technical knowledge with my keen eye for
          design to create a beautiful product. <strong>My goal is to always build
          applications that are scalable and efficient under the hood while
          providing engaging, pixel-perfect user experiences</strong>.
        </p>
        <p className={styles.paragraphThree}>
        <strong>When I'm not in front of a computer screen</strong>, I'm probably At the gym, or crossing off another item on my bucket list.
        </p>
      </header>
    );
  }
}

export default Header;

const tl = gsap.timeline({
  defaults: { duration: 0.75, ease: "power3.out" },
});

window.onload = () => {
  tl.fromTo(
    "img",
    { scale: 0.9, borderRadius: "0rem" },
    {
      scale: 1,
      borderRadius: "2rem",
      duration: 2.5,
      ease: "elastic.out(1.5, 1",
    }
  );
}
