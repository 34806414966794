import React from "react";
import styles from "./Navbar.module.css";
import { gsap } from "gsap";
import Resume from "../assets/cv-AlirezaShafii.pdf";

function Navbar() {
  const toggler = () => {
    document.body.classList.toggle("dark");
    if (document.body.classList.contains("dark")) {
      gsap.fromTo(
        "body",
        {background: "#f8fafc", color: "#161825"},
        {background: "#161825", color: "#f8fafc"}
      );
      document.querySelector("nav span").innerHTML = `<svg
                                                        className={styles.toggle}
                                                        preserveAspectRatio="xMidYMid meet"
                                                        viewBox="0 0 32 32"
                                                      >
                                                        <path
                                                          d="M13.502 5.414a15.075 15.075 0 0 0 11.594 18.194a11.113 11.113 0 0 1-7.975 3.39c-.138 0-.278.005-.418 0a11.094 11.094 0 0 1-3.2-21.584M14.98 3a1.002 1.002 0 0 0-.175.016a13.096 13.096 0 0 0 1.825 25.981c.164.006.328 0 .49 0a13.072 13.072 0 0 0 10.703-5.555a1.01 1.01 0 0 0-.783-1.565A13.08 13.08 0 0 1 15.89 4.38A1.015 1.015 0 0 0 14.98 3z"
                                                          fill="currentColor"
                                                        ></path>
                                                      </svg>`;
    } else if (!document.body.classList.contains("dark")) {
      gsap.fromTo(
        "body",
        {background: "#161825", color: "#f8fafc"},
        {background: "#f8fafc", color: "#161825"}
      );
      document.querySelector("nav span").innerHTML = `<svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        className="w-5 h-5"
                                                      >
                                                        <path d="M10 2a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 2zM10 15a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5A.75.75 0 0110 15zM10 7a3 3 0 100 6 3 3 0 000-6zM15.657 5.404a.75.75 0 10-1.06-1.06l-1.061 1.06a.75.75 0 001.06 1.06l1.06-1.06zM6.464 14.596a.75.75 0 10-1.06-1.06l-1.06 1.06a.75.75 0 001.06 1.06l1.06-1.06zM18 10a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5A.75.75 0 0118 10zM5 10a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5A.75.75 0 015 10zM14.596 15.657a.75.75 0 001.06-1.06l-1.06-1.061a.75.75 0 10-1.06 1.06l1.06 1.06zM5.404 6.464a.75.75 0 001.06-1.06l-1.06-1.06a.75.75 0 10-1.061 1.06l1.06 1.06z" />
                                                      </svg>`;
    }
  };

  return (
    <nav>
      <div>
        <a
          href="https://github.com/alirezashafii"
          target="_blank"
          rel="noopener noreferrer"
        >
          ./shafii
        </a>
        <section>
          <a href={Resume} target="_blank" rel="noopener noreferrer">
            RESUME
          </a>
          <span onClick={toggler}>
            <svg
              className={styles.toggle}
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M13.502 5.414a15.075 15.075 0 0 0 11.594 18.194a11.113 11.113 0 0 1-7.975 3.39c-.138 0-.278.005-.418 0a11.094 11.094 0 0 1-3.2-21.584M14.98 3a1.002 1.002 0 0 0-.175.016a13.096 13.096 0 0 0 1.825 25.981c.164.006.328 0 .49 0a13.072 13.072 0 0 0 10.703-5.555a1.01 1.01 0 0 0-.783-1.565A13.08 13.08 0 0 1 15.89 4.38A1.015 1.015 0 0 0 14.98 3z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
        </section>
      </div>
      <hr />
    </nav>
  );
}

export default Navbar;
