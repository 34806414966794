import React, { Component } from "react";
import styles from "./Main.module.css";
import Project from "./Project";
import Github from "../assets/icons/github";
import Twitter from "../assets/icons/twitter";
import Linkedin from "../assets/icons/linkedin";

class Main extends Component {
  // state = {  }
  render() {
    return (
      <main>
        <h3>Skills</h3>
        <p>Here are a few technologies I’ve been working with:</p>
        <section className={styles.skills}>
          <article>
            <h4>Languages</h4>
            <ul>
              <li>JavaScript ES6+</li>
              <li>TypeScript</li>
              <li>HTML/XML</li>
              <li>CSS/Sass</li>
              <li>SQL</li>
            </ul>
          </article>
          <article>
            <h4>Lib / FWK</h4>
            <ul>
              <li>Reactjs</li>
              <li>Tailwind CSS</li>
              <li>threejs</li>
              <li>JSAP</li>
              <li>Node</li>
            </ul>
          </article>
          <article>
            <h4>Tools</h4>
            <ul>
              <li>Linux terminal</li>
              <li>Chrome DevTools</li>
              <li>Git and Github</li>
              <li>Postman</li>
              <li>MongoDB</li>
            </ul>
          </article>
        </section>
        <h3>Past projects</h3>
        <p className={styles.tags}>
          <span>shopping-cart</span>
          <span>search-filter</span>
          <span>vanillajs-spa</span>
        </p>
        <section className={styles.Projects}>
          <Project
            title="vanillajs-spa"
            description="Single page application using vanilla JavaScript."
            github="https://github.com/alirezashafii/vanillajs-spa"
          />
          <Project
            title="search-filter"
            description="Implementing search and filter products received through API."
            github="https://github.com/alirezashafii/search-filter"
          />
          <Project
            title="shopping-cart"
            description="JavaScript shopping cart project Based on object oriented programming."
            github="https://github.com/alirezashafii/shopping-cart"
          />
        </section>
        <div className={styles.contact}>
          <p>
            I’m currently looking for any new opportunities, my inbox is always
            open. Whether you have a question or just want to say hi, I’ll try
            my best to get back to you!
          </p>
          <a
            className={styles.email}
            href="mailto:alireza_shafii@outlook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            alireza_shafii@outlook.com
          </a>
          <div className={styles.social}>
            <a
              href="https://twitter.com/alirezashafii"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
            <a
              href="https://github.com/alirezashafii"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Github />
            </a>
            <a
              href="https://ir.linkedin.com/in/alirezashafii"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </a>
            <span>|</span>
            <h5>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M5.404 14.596A6.5 6.5 0 1116.5 10a1.25 1.25 0 01-2.5 0 4 4 0 10-.571 2.06A2.75 2.75 0 0018 10a8 8 0 10-2.343 5.657.75.75 0 00-1.06-1.06 6.5 6.5 0 01-9.193 0zM10 7.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
                  clipRule="evenodd"
                />
              </svg>
              <span>alirezashafii</span>
            </h5>
          </div>
        </div>
      </main>
    );
  }
}

export default Main;
